import React from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {

  return (
    <div>
    </div>
  );
}

export default LandingPage;
